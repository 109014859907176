<template>
  <div class="row w-100 h-100 noselect">
    <div class="col-lg-8 col-md-6 col-sm-4">
      <img
        id="background-image"
        class="d-none d-lg-block d-sm-block"
        src="@/assets/login-background.jpg"
      />
    </div>
    <div class="col-lg-4 col-md-6 col-sm-8" id="right-panel">
      <b-form @submit="onResetPassword" style="margin-top: 8rem;">
        <!--password reset-->
        <b-img class="pb-5 w-50" :src="require('@/assets/DC_Logo.svg')" />
        <p class="result-message" v-if="resettingPassword">
          Your new password has been set!
        </p>
        <p class="error-message" v-if="noCode">
          Invalid code. Please click the link in the email.
        </p>
        <b-input-group class="click-cursor">
          <b-input-group-prepend class="input-noborder">
            <span class="input-group-text">
              <font-awesome-icon size="2x" :icon="['fa', 'lock']" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            v-model="form.password"
            type="password"
            class="login-input"
            size="lg"
            placeholder="New Password"
            required
          >
          </b-form-input>
        </b-input-group>
        <!-- Login Button -->
        <b-input-group class="pt-4 click-cursor">
          <button class="btn btn-primary btn-block shadow-none login-button">
            Reset Password
          </button>
        </b-input-group>
      </b-form>
      <div>
        <!-- powered by MetaChef -->
        <img class="copyright" src="@/assets/DCPowered.png" />
      </div>
    </div>
  </div>
</template>

<script>
// Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

library.add(faLock);
library.add(faUserCircle);

import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "ChangePassword",
  data() {
    return {
      form: {},
      resettingPassword: false,
      noCode: false
    };
  },
  mounted() {},
  methods: {
    onResetPassword: function(e) {
      var actionCode;
      console.log(this.$route.query.oobCode == null);
      e.preventDefault();
      if (this.$route.query.oobCode != null) {
        actionCode = this.$route.query.oobCode;
      } else {
        this.noCode = true;
        return;
      }
      //var mode = this.$route.query.code;

      firebase
        .auth()
        .confirmPasswordReset(actionCode, this.form.password)
        .then(() => {
          this.resettingPassword = true;
        })
        .catch(() => {
          console.log("invalid code?!");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/custom.scss";

#background-image {
  left: -15%;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  object-fit: cover;
}

#right-panel {
  background-color: white;
  height: 100vh;
  right: 0;
}

form {
  padding-left: 12% !important;
  padding-right: 5% !important;
}

.input-group-text > svg {
  width: 0.5em !important;
  height: 0.5em !important;
  color: #adadad;
}

.input-group-text {
  border-radius: 10px 0px 0px 10px;
  color: $input-group-text-colour;
}

input {
  border-left-style: none !important;
  font-family: "Franklin Gothic Book";
}

.login-input {
  border-left: none;
  background-color: $input-group-text-colour;
}

.input-noborder {
  border-right: none;
}

.error-message {
  text-align: left;
  color: red;
}

.result-message {
  text-align: left;
  color: black;
}

.form-control-lg {
  border-radius: 10px 10px 10px 10px;
}

.btn {
  border-radius: 10px 10px 10px 10px;
}

.copyright {
  position: absolute;
  left: 9%;
  bottom: 4%;
  float: left;
}

.login-button {
  background-color: $login-button-colour;
  border: none !important;
  height: 48px;
}
.login-button:hover {
  background-color: $login-button-colour-hover;
}
.login-button:focus {
  background-color: #56abb0;
}
</style>
